import React from "react";
import AdminNavbar from "./AdminNavbar";
import axios from 'axios';

class ManageUserActivities extends React.Component
{
  constructor(prop)
  {
    super(prop)
  }
  state={
    data:[]
  }

  componentDidMount(){
    //get request
    axios.get('https://pxm5627.uta.cloud/users.php').then(res => 
    {
    
    this.setState({data: res.data});
    console.log(this.state.data)
       }); 
    
    }

  render()
  {
    return (
      <div className="amcpage_container">
        <div className="amcmain-container">
          <AdminNavbar />
          <div className="amctable">
            <table className="amcgrades-table">
              <thead>
                <tr className="amcgrades-table-row">
                  <th className="amcgrades-table-header">First Name</th>
                  <th className="amcgrades-table-header">Last Name</th>
                  <th className="amcgrades-table-header">Email</th>
              
                  <th className="amcgrades-table-header">User Type</th>
                  <th className="amcgrades-table-header">Action</th>
                  <th className="amcgrades-table-header">User Permission</th>
                </tr>
              </thead>
              <tbody>
              {this.state.data.map((result) => {
            return (
                <tr className="amcgrades-table-row">
                  <td>{result.name}</td>
                  <td>{result.lname}</td>
                  <td>{result.email}</td>
                
                  <td>{result.role}</td>
                  <td>
                    <a href="monitor.html">
                      <button type="submit">Monitor</button>
                    </a>
                  </td>
                  <td>
                    <input
                      type="radio"
                      name="option"
                      value="Option 1"
                      id="Option 1"
                    />
                    <label htmlFor="Option 1">
                      Allow permission to manage course
                    </label>
                    <br />
                    <input
                      type="radio"
                      name="option"
                      value="Option 2"
                      id="Option 2"
                    />
                    <label htmlFor="Option 2">
                      Allow permission to manage program
                    </label>
                  </td>
                </tr>


      
)
})}
              
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}


export default ManageUserActivities;
